import React from 'react'
import { Link } from 'gatsby'
import Waypoint from 'react-waypoint'

import Scrollspy from 'react-scrollspy'
import Scroll from '../Scroll'
import './style.scss'
class Navi extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stickyNav: false,
      navBarExpanded: false,
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }))
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }))
  }
  toggleNavigation() {
    this.setState(() => ({ navBarExpanded: !this.state.navBarExpanded }))
  }
  render() {
    const { location, title } = this.props
    return (
      <div className="topbar header" id="top">
        <div
          id="nav"
          className={this.state.stickyNav ? ' navbar-fixed-top' : ''}
        >
          <div className="container">
            <nav className="navbar navbar-expand-lg navbar-light header-nav-bar">
              <Scrollspy items={['top']}>
                <Scroll type="id" element="top">
                  <a className="navbar-brand" href="#">
                    <img
                      src={require('../../assets/images/logo.png')}
                      width="104"
                      height="30"
                      alt="Webgrafy"
                    />
                  </a>
                </Scroll>
              </Scrollspy>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded={this.state.navBarExpanded}
                aria-label="Toggle navigation"
                onClick={() => this.toggleNavigation()}
              >
                <span className="navbar-toggler-icon" />
              </button>
              <div
                className={
                  'collapse navbar-collapse' +
                  (this.state.navBarExpanded ? ' show' : '')
                }
                id="navbarSupportedContent"
              >
                <span className="navbar-nav mr-auto" />
                <Scrollspy
                  items={[
                    'about',
                    'packages',
                    'services',
                    'driven',
                    'portfolio',
                    'testimonials',
                    'contact',
                  ]}
                  currentClassName="is-active"
                  className="navbar-nav"
                  offset={-300}
                >
                  <li className="nav-item">
                    <Scroll type="id" element="about">
                      <a className="nav-link" href="#">
                        About Us
                      </a>
                    </Scroll>
                  </li>
                  <li className="nav-item">
                    <Scroll type="id" element="services">
                      <a className="nav-link" href="#">
                        Services
                      </a>
                    </Scroll>
                  </li>
                  <li className="nav-item">
                    <Scroll type="id" element="portfolio">
                      <a className="nav-link" href="#">
                        Portfolio
                      </a>
                    </Scroll>
                  </li>
                  <li className="nav-item">
                    <Scroll type="id" element="packages">
                      <a className="nav-link" href="#">
                        Packages
                      </a>
                    </Scroll>
                  </li>
                  <li className="nav-item">
                    <Scroll type="id" element="contact">
                      <a className="nav-link" href="#">
                        Contact
                      </a>
                    </Scroll>
                  </li>
                </Scrollspy>
              </div>
            </nav>
          </div>
        </div>
        <div className="row banner">
          <div className="col-md-12">
            <div className="banner-text">
              <h1 className="responsive-headline">
                We Make Your Business Work For You!
              </h1>
              <h3>Logos, Business Cards, Websites and more...</h3>
              <div className="quote">
                <Scroll type="id" element="portfolio">
                  <button type="submit" className="btn btn-outline-danger">
                    Portfolio
                  </button>
                </Scroll>
              </div>
            </div>
          </div>
        </div>
        <Waypoint
          id="intro"
          onEnter={this._handleWaypointEnter}
          onLeave={this._handleWaypointLeave}
        />
      </div>
    )
  }
}

export default Navi
