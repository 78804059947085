import React from 'react'
import { Link } from 'gatsby'
import './style.scss'

class Footer extends React.Component {
  render() {
    const { title, author } = this.props
    return (
      <footer className="footer_all">
        <div className="footer">
          <div className="container spacer b-t">
            <div className="row">
              <div className="col-lg-4 col-md-4">
                <p>&copy; 2018 Copyright {title}</p>
              </div>
              <div className="col-lg-4 col-md-4">
                <ul>
                  <li>
                    <a href="#" className="d-flex">
                      {' '}
                      <i className="fa fa-map-marker mr-2" />Montreal, Canada.
                    </a>
                  </li>
                  <li>
                    <div className="d-flex">
                      <i className="fa fa-envelope mr-2" />
                      <a href="mailto:info.webgrafy@gmail.com">
                        info.webgrafy@gmail.com
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="round-social light">
                  {' '}
                  <a href="#" className="link">
                    <i className="fa fa-facebook" />
                  </a>{' '}
                  <a href="#" className="link">
                    <i className="fa fa-instagram" />
                  </a>{' '}
                  <a href="#" className="link">
                    <i className="fa fa-pinterest" />
                  </a>{' '}
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
